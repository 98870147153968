"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.controlLoader = void 0;
const loader = document.querySelector(".loader");
const controlLoader = (show) => {
    if (show) {
        loader.classList.remove("hide");
    }
    else {
        loader.classList.add("hide");
    }
};
exports.controlLoader = controlLoader;
