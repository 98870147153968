"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setProjectUserParams = exports.addProject = exports.setCurrentProject = exports.setProjects = exports.setUser = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const constants_1 = require("./constants");
exports.setUser = (0, toolkit_1.createAction)(constants_1.SET_USER);
exports.setProjects = (0, toolkit_1.createAction)(constants_1.SET_PROJECTS);
exports.setCurrentProject = (0, toolkit_1.createAction)(constants_1.SET_CURRENT_PROJECT);
exports.addProject = (0, toolkit_1.createAction)(constants_1.ADD_PROJECT);
exports.setProjectUserParams = (0, toolkit_1.createAction)(constants_1.SET_PROJECT_USER_PARAMS);
