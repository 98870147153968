"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SET_CLIENT_STAT_TOUR_VIEW = exports.SET_CLIENT_CLEAR = exports.SET_CLIENT = exports.SET_CLIENTS_FILTER_CLEAR = exports.SET_CLIENTS_FILTER = exports.SET_CLIENTS_PAGE = exports.SET_CLIENTS = void 0;
exports.SET_CLIENTS = "@@CLIENTS/SET_CLIENTS";
exports.SET_CLIENTS_PAGE = "@@CLIENTS/SET_CLIENTS_PAGE";
exports.SET_CLIENTS_FILTER = "@@CLIENTS/SET_CLIENTS_FILTER";
exports.SET_CLIENTS_FILTER_CLEAR = "@@CLIENTS/SET_CLIENTS_FILTER_CLEAR";
exports.SET_CLIENT = "@@CLIENTS/SET_CLIENT";
exports.SET_CLIENT_CLEAR = "@@CLIENTS/SET_CLIENT_CLEAR";
exports.SET_CLIENT_STAT_TOUR_VIEW = "@@CLIENTS/SET_CLIENT_STAT_TOUR_VIEW";
