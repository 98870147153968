"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chartPieOptions = void 0;
exports.chartPieOptions = {
    chart: {
        backgroundColor: null,
        plotBackgroundColor: null,
        plotBorderWidth: null,
        margin: [0, 0, 0, 0],
        spacing: [0, 0, 0, 0],
        height: 200,
        width: 200,
    },
    credits: { enabled: false },
    title: null,
    tooltip: {
        pointFormat: "{series.name}: {point.y} <b>{point.percentage:.1f}%</b>",
    },
};
