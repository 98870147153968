"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectProjectUserParams = exports.selectCurrentProject = exports.selectProjects = exports.selectUser = void 0;
const selectUser = (state) => state.authReducer.userLogin;
exports.selectUser = selectUser;
const selectProjects = (state) => state.authReducer.projects;
exports.selectProjects = selectProjects;
const selectCurrentProject = (state) => state.authReducer.currentProject;
exports.selectCurrentProject = selectCurrentProject;
const selectProjectUserParams = (state) => state.authReducer.projectUserParams;
exports.selectProjectUserParams = selectProjectUserParams;
