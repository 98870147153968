"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const FormGroupContainer = ({ error, label, visible = true, controlID, children, className, style, }) => {
    const [open, setOpen] = (0, react_1.useState)(false);
    const showErroFields = (error) => {
        if (error) {
            setOpen(true);
        }
        else {
            setOpen(false);
        }
    };
    (0, react_1.useEffect)(() => {
        showErroFields(error);
    }, [error]);
    return ((0, jsx_runtime_1.jsxs)(react_bootstrap_1.FormGroup, Object.assign({ style: style, className: `${className} ${error ? "has-error" : ""}`, controlId: controlID }, { children: [label && visible && (0, jsx_runtime_1.jsx)(react_bootstrap_1.Form.Label, { children: label }), children, (0, jsx_runtime_1.jsx)(react_bootstrap_1.Collapse, Object.assign({ in: open }, { children: (0, jsx_runtime_1.jsx)(react_bootstrap_1.Form.Control.Feedback, Object.assign({ type: "invalid" }, { children: error && error[0] })) }))] })));
};
exports.default = FormGroupContainer;
