"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateStatFilters = exports.setStatTourSteps = exports.setStatTour = exports.setStatCohortPaycv = exports.setStatBase = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const constants_1 = require("./constants");
exports.setStatBase = (0, toolkit_1.createAction)(constants_1.SET_STAT_BASE);
exports.setStatCohortPaycv = (0, toolkit_1.createAction)(constants_1.SET_STAT_COHORT_PAYCV);
exports.setStatTour = (0, toolkit_1.createAction)(constants_1.SET_STAT_TOUR_TOTAL);
exports.setStatTourSteps = (0, toolkit_1.createAction)(constants_1.SET_STAT_TOUR_STEPS);
exports.updateStatFilters = (0, toolkit_1.createAction)(constants_1.SET_STAT_FILTER);
