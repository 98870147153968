"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const Sentry = __importStar(require("@sentry/react"));
const react_1 = require("react");
const sharedUtils_1 = require("src/utils/sharedUtils");
if (!sharedUtils_1.isDev) {
    Sentry.init({
        dsn: "https://2770cac91041ba8ba5b93388671a6607@o4505636891131904.ingest.sentry.io/4505793262977024",
        integrations: [new Sentry.BrowserTracing()],
        release: SENTRY_RELEASE_NAME,
        tracePropagationTargets: ["https:/*.adopty.*/*"],
        denyUrls: [/extensions\//i, /^chrome:\/\//i, /^chrome-extension:\/\//i, /metrika\//i],
    });
}
class ErrorBoundary extends react_1.Component {
    constructor(props) {
        super(props);
    }
    componentDidCatch(err, info) {
        if (!sharedUtils_1.isDev) {
            Sentry.captureException(err);
        }
    }
    render() {
        return this.props.children;
    }
}
exports.default = ErrorBoundary;
