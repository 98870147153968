"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NAVIGATION_SECTIONS = exports.SETTINGS_SUB_NAVIGATIONS = void 0;
exports.SETTINGS_SUB_NAVIGATIONS = [
    {
        label: "general settings",
        value: "/settings/project",
        permission: "projects",
    },
    {
        label: "Users",
        value: "/settings/users",
        permission: "users",
    },
    {
        label: "setup",
        value: "/settings/setup",
        permission: "settings",
    },
];
exports.NAVIGATION_SECTIONS = [
    {
        label: "clients",
        value: "/clients",
        permission: "clients",
    },
    {
        label: "statistics",
        value: "/stat",
        permission: "statistics",
    },
    {
        label: "settings",
        value: "/settings",
        permission: "settings",
        children: exports.SETTINGS_SUB_NAVIGATIONS,
    },
];
