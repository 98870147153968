"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_bootstrap_1 = require("react-bootstrap");
const Alert = ({ title, description, variant, icon, className = "", action }) => {
    return ((0, jsx_runtime_1.jsxs)(react_bootstrap_1.Alert, Object.assign({ variant: variant, className: className }, { children: [!!icon && (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "alert__icon" }, { children: icon })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "alert__content" }, { children: [!!title && (0, jsx_runtime_1.jsx)(react_bootstrap_1.Alert.Heading, { children: title }), !!description && ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: `alert__subheader ${!title ? "alert__subheader_main" : ""}` }, { children: description })))] })), !!action && ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: "alert__action" }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ onClick: (e) => {
                        action.onClick ? action.onClick(e) : null;
                    }, className: `btn ${action.btn_class || ""}` }, { children: action.title })) })))] })));
};
exports.default = Alert;
