"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectClientStatTourView = exports.selectClient = exports.selectClientsPage = exports.selectClientsFilter = exports.selectClients = void 0;
const selectClients = (state) => state.clientsReducer.clients;
exports.selectClients = selectClients;
const selectClientsFilter = (state) => state.clientsReducer.filter;
exports.selectClientsFilter = selectClientsFilter;
const selectClientsPage = (state) => state.clientsReducer.clientsPage;
exports.selectClientsPage = selectClientsPage;
const selectClient = (state) => state.clientsReducer.client.item;
exports.selectClient = selectClient;
const selectClientStatTourView = (state) => state.clientsReducer.client.stat_tour_view;
exports.selectClientStatTourView = selectClientStatTourView;
