"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const toolkit_1 = require("@reduxjs/toolkit");
const actions_1 = require("src/stores/applicationStore/tours/actions");
const serializers_1 = require("src/stores/constructorStore/hotspots/serializers");
const initialState = {
    tours: null,
};
const tourReducer = (0, toolkit_1.createReducer)(initialState, (builder) => {
    builder.addCase(actions_1.setTours, (state, action) => {
        state.tours = (0, serializers_1.accumulatorSort)(action.payload);
    });
});
exports.default = tourReducer;
