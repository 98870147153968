"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setClientStatTourView = exports.setClientClear = exports.setClient = exports.setClientsFilterClear = exports.setClientsFilter = exports.setClientsPage = exports.setClients = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const constants_1 = require("./constants");
exports.setClients = (0, toolkit_1.createAction)(constants_1.SET_CLIENTS);
exports.setClientsPage = (0, toolkit_1.createAction)(constants_1.SET_CLIENTS_PAGE);
exports.setClientsFilter = (0, toolkit_1.createAction)(constants_1.SET_CLIENTS_FILTER);
exports.setClientsFilterClear = (0, toolkit_1.createAction)(constants_1.SET_CLIENTS_FILTER_CLEAR);
exports.setClient = (0, toolkit_1.createAction)(constants_1.SET_CLIENT);
exports.setClientClear = (0, toolkit_1.createAction)(constants_1.SET_CLIENT_CLEAR);
exports.setClientStatTourView = (0, toolkit_1.createAction)(constants_1.SET_CLIENT_STAT_TOUR_VIEW);
