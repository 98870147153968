"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const sharedUtils_1 = require("src/utils/sharedUtils");
const TextOrLink = ({ text, field }) => {
    const mailRegExp = new RegExp(/(e|)mail/gi);
    const [isMail, isLink] = (0, react_1.useMemo)(() => [
        mailRegExp.test(field),
        (0, sharedUtils_1.testUrl)(text)
    ], []);
    if (!isMail && isLink) {
        return (0, jsx_runtime_1.jsx)("a", Object.assign({ href: text, target: "_blank" }, { children: text }));
    }
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: text });
};
exports.default = TextOrLink;
