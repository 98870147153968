"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const toolkit_1 = require("@reduxjs/toolkit");
const actions_1 = require("./actions");
const initialState = {
    loginPage: {
        show: false,
    },
};
const modalsReducer = (0, toolkit_1.createReducer)(initialState, (builder) => {
    builder
        .addCase(actions_1.setModalProps, (state, action) => {
        state[action.payload.name] = action.payload.data;
    })
        .addCase(actions_1.setModalShow, (state, action) => {
        state[action.payload.name] = {
            show: action.payload.show,
        };
    })
        .addCase(actions_1.setModalLoading, (state, action) => {
        state[action.payload.name].loading = action.payload.loading;
    })
        .addCase(actions_1.setModalErrors, (state, action) => {
        state[action.payload.name].errors = action.payload.errors;
    });
});
exports.default = modalsReducer;
