"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const toolkit_1 = require("@reduxjs/toolkit");
const actions_1 = require("./actions");
const initialState = {
    base: null,
    tour: null,
    tour_steps: null,
    cohort_paycv: null,
    filter: {
        period: "today",
        date_from: null,
        date_to: null,
        tour: null,
        finished: null,
    },
};
const statReducer = (0, toolkit_1.createReducer)(initialState, (builder) => {
    builder.addCase(actions_1.setStatBase, (state, action) => {
        state.base = action.payload;
    });
    builder.addCase(actions_1.setStatCohortPaycv, (state, action) => {
        state.cohort_paycv = action.payload;
    });
    builder.addCase(actions_1.setStatTour, (state, action) => {
        state.tour = action.payload;
    });
    builder.addCase(actions_1.setStatTourSteps, (state, action) => {
        state.tour_steps = action.payload;
    });
    builder.addCase(actions_1.updateStatFilters, (state, action) => {
        state.filter = Object.assign(Object.assign({}, state.filter), action.payload);
    });
});
exports.default = statReducer;
