"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const toolkit_1 = require("@reduxjs/toolkit");
const actions_1 = require("./actions");
const initialState = {
    isLoading: 0,
};
const loaderReducer = (0, toolkit_1.createReducer)(initialState, (builder) => {
    builder.addCase(actions_1.setLoading, (state, action) => {
        state.isLoading = action.payload ? state.isLoading + 1 : state.isLoading - 1;
    });
});
exports.default = loaderReducer;
