"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const toolkit_1 = require("@reduxjs/toolkit");
const actions_1 = require("./actions");
const initialState = {
    users: null,
};
const usersReducer = (0, toolkit_1.createReducer)(initialState, (builder) => {
    builder.addCase(actions_1.setUsers, (state, action) => {
        state.users = action.payload;
    });
});
exports.default = usersReducer;
