"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clientsReducer = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const actions_1 = require("./actions");
const initialState = {
    client: {
        item: null,
        stat_tour_view: null,
    },
    clients: null,
    clientsPage: null,
    filter: {
        q: null,
        page: null,
        tour_view_from: null,
        tour_view_to: null,
    },
};
exports.clientsReducer = (0, toolkit_1.createReducer)(initialState, (builder) => {
    builder
        .addCase(actions_1.setClients, (state, action) => {
        state.clients = action.payload;
    })
        .addCase(actions_1.setClientsPage, (state, action) => {
        state.clientsPage = {
            count: action.payload.count,
            current_page: action.payload.current_page,
            page_size: action.payload.page_size,
        };
    })
        .addCase(actions_1.setClientsFilter, (state, action) => {
        state.filter = Object.assign(Object.assign({}, state.filter), action.payload);
    })
        .addCase(actions_1.setClientsFilterClear, (state, action) => {
        state.filter = Object.assign({}, initialState.filter);
    })
        .addCase(actions_1.setClientStatTourView, (state, action) => {
        state.client.stat_tour_view = action.payload;
    })
        .addCase(actions_1.setClientClear, (state, action) => {
        state.client = initialState.client;
    })
        .addCase(actions_1.setClient, (state, action) => {
        state.client.item = action.payload;
    });
});
