"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const toolkit_1 = require("@reduxjs/toolkit");
const actions_1 = require("./actions");
const initialState = {
    userLogin: {
        admin_role: null,
        date_joined: "",
        email: "",
        first_name: "",
        id: null,
        is_active: false,
        last_login: "",
        last_name: "",
        phone_number: "",
        user_role: null,
        adopty_auth_hash: "",
    },
    projects: [],
    currentProject: null,
    projectUserParams: null,
};
const combinedAuthReducer = (0, toolkit_1.createReducer)(initialState, (builder) => {
    builder
        .addCase(actions_1.setUser, (state, action) => {
        state.userLogin = action.payload;
    })
        .addCase(actions_1.setProjects, (state, action) => {
        state.projects = action.payload;
    })
        .addCase(actions_1.setCurrentProject, (state, action) => {
        let isProjectAdded = false;
        const newProjects = state.projects.map((project) => {
            if (action.payload.id === project.id) {
                isProjectAdded = true;
                return action.payload;
            }
            return project;
        });
        if (!isProjectAdded) {
            newProjects.push(action.payload);
        }
        state.projects = newProjects;
        state.currentProject = action.payload;
    })
        .addCase(actions_1.addProject, (state, action) => {
        state.projects.push(action.payload);
    })
        .addCase(actions_1.setProjectUserParams, (state, action) => {
        state.projectUserParams = action.payload;
    });
});
exports.default = combinedAuthReducer;
